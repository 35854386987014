import React from 'react';

import PageWrap from 'components/PageWrap';
import { Section } from 'components/Section';
import { PagePicTitle } from 'components/PagePicTitle';

import { FeaturePoint } from 'components/FeaturePoint';
import CallsToAction from 'components/CallsToAction';
import { Separator } from 'components/Separator';
import { SEO } from 'components/Helmets';

import GenPhlIcon from 'media/shared/modIcons/GenPhl.inline.svg';

import typeSetupImg from 'media/Screenshots/PhysicalThings/TypeSetup.png';
import searchFiltersImg from 'media/Screenshots/PhysicalThings/SearchFilters.png';
import reportingImg from 'media/Screenshots/PhysicalThings/Reporting.png';
import IndyLink from 'components/IndyLink';

export default function ModPhysicalThings({data}){
    const CTATypes = ['SignUp', 'Demo'];
    const pagePath = "/modules/physical_things";

    return (
        <PageWrap noSEO={true} path={pagePath}>
            <SEO
                title="The Physical Things Module - Mashoom"
                description="Store any information about a set of physical things"
                previewImgKey="genphl"
                path={pagePath}
            />
            <PagePicTitle
                imageCmp={GenPhlIcon}
                imageAlt="Physical things module icon"
            >
                <h1>The Physical Things Module</h1>
                <p>A completely customizable database of physical things. Whilst providing a replacement to spreadsheets, it can also be used as a powerful data management engine in advanced applications.</p>
            </PagePicTitle>
            <FeaturePoint
                title={"It starts with defining what your \"things\" are"}
                image={typeSetupImg}
                pageInd="1"
            >
                <p>Think of a spreadsheet based list, your list would have a certain number of columns of information you would be collecting. At a basic level, this is what setting up a "type" is; describing what information you are collecting.</p>
                <p className="paraSpaceTop">Any number of individual types can be created, but say you manage a fleet of computers; wouldn't it be useful to collect a slightly different set of information about desktop computers versus laptops? For instance the battery age / replacement date would be useful for laptops but irrelevant to desktops.</p>
                <p className="paraSpaceTop">This is where Mashoom's powerful type hierarchy comes into play. By setting up the fields common to the whole set, then creating sub types with their own specific fields and so on, your fields aren't duplicated across each type. This is good for both data management and accuracy, for instance, if you want to add a new type of computer (mobile phone?) you don't have to recreate every field; just add a sub type.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Once we know how your data works, we can supercharge what you can do"
                image={searchFiltersImg}
                pageInd="2"
            >
                <p>Ever got frustrated that spreadsheet filters only get you so far? So did we, we made our own, and a bespoke database engine to handle them.</p>
                <p className="paraSpaceTop">Build up a search made from a set of simple filters, complete with "AND" / "OR" lookups and brackets to pick out a specific part of your data. Whilst you can use these just to find stuff, this is powerful enough to drive auditing and assess your asset estate.</p>
                <p className="paraSpaceTop">Our bespoke database engine has been tested with tens of thousands of "things" with hundreds of fields; we haven't broken it yet or even come close!</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Reporting that tells you what you need to know, not what we can do"
                image={reportingImg}
                pageInd="3"
            >
                <p>Many reporting tools focus on a specific use case and provide some graphs and reports only for those means. We have designed something very different; you can analyse your data in an infinite number of ways that you decide.</p>
                <p className="paraSpaceTop">Want to know how many of each value you have of a field? What about that but for only a selection of your population? Or that but as a stacked line chart over time? Yes is the answer. We also can quickly / cheaply provide custom reports for the most specific of requirements, <IndyLink adr="/about/#ContactUs"> we would love to hear from you</IndyLink>.</p>
                <p className="paraSpaceTop">We are practical about what people use reports for though, so every report (yes even the graphs!) are downloadable in spreadsheet format so you can use your own colours / formatting and embed it nicely in a presentation etc. In the same vein, if you want to download some or all of your data in a simple column/row format, you can.</p>
            </FeaturePoint>
            <Section id="FeatureCTA">
                <div id="FeatureCTACont">
                    <h2>Like what your hear?</h2>
                    <CallsToAction types={CTATypes}></CallsToAction>
                </div>
            </Section>
        </PageWrap>
    );
}